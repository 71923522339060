import React from 'react';

const PasswordReset = React.lazy(() =>
  import('./views/Pages/PasswordReset/PasswordReset')
);
const PasswordResetForm = React.lazy(() =>
  import('./views/Pages/PasswordReset/PasswordResetForm')
);
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Statistics = React.lazy(() => import('./views/Statistics/Statistics'));
const LiveStatistics = React.lazy(() =>
  import('./views/LiveStatistics/LiveStatistics')
);
const HelpSupport = React.lazy(() => import('./views/HelpSupport'));
const Pricing = React.lazy(() => import('./views/Pricing'));
const Extensions = React.lazy(() => import('./views/Extensions'));
const Groups = React.lazy(() => import('./views/Groups'));
const GroupOverview = React.lazy(() =>
  import('./views/GroupOverview/GroupOverview')
);
const Clients = React.lazy(() => import('./views/Reseller/Clients'));
const Invoices = React.lazy(() => import('./views/Invoices/Invoices'));
const InvoiceDetails = React.lazy(() =>
  import('./views/Invoices/InvoiceDetails')
);
const InvoiceCDR = React.lazy(() => import('./views/Invoices/InvoiceCDR'));
const ResellerInvoices = React.lazy(() =>
  import('./views/Reseller/Invoices/Invoices')
);
const ResellerInvoiceDetails = React.lazy(() =>
  import('./views/Reseller/Invoices/InvoiceDetails')
);
const RingGroups = React.lazy(() => import('./views/RingGroups'));
const Sites = React.lazy(() => import('./views/Sites'));
const DidRouting = React.lazy(() => import('./views/DidRouting'));
const CallForwarding = React.lazy(() =>
  import('./views/CallForwarding/CallForwarding')
);
const CallFlowToggles = React.lazy(() => import('./views/CallFlowToggles'));
const Phonebooks = React.lazy(() => import('./views/Phonebooks'));
const Devices = React.lazy(() => import('./views/Devices'));
const Dss = React.lazy(() => import('./views/Devices/Dss'));
const CallFlow = React.lazy(() => import('./views/CallFlow'));
const FifoQueue = React.lazy(() => import('./views/FifoQueue'));
const Voicemail = React.lazy(() => import('./views/Voicemail'));
const VoicemailMessages = React.lazy(() =>
  import('./views/Voicemail/VoicemailMessages')
);
const CallFlowGUI = React.lazy(() => import('./views/CallFlowGUI'));
const MediaLibrary = React.lazy(() => import('./views/MediaLibrary'));
const WakeupCall = React.lazy(() => import('./views/WakeupCall'));
const CDRViewer = React.lazy(() => import('./views/CDRViever'));
const Phone = React.lazy(() => import('./views/Phone'));
const SubMenu = React.lazy(() => import('./components/SubMenu'));
const Impersonate = React.lazy(() => import('./views/Impersonate'));
const TimeSchedule = React.lazy(() =>
  import('./views/TimeSchedule/TimeSchedule')
);
const Integrations = React.lazy(() => import('./views/Integrations'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    authKey: 'home',
    transKey: 'breadcrumb_home',
    exact: true,
    name: 'Home'
  },
  {
    path: '/reset_password/forgot',
    authKey: 'password_reset',
    exact: true,
    name: 'Password reset',
    component: PasswordReset
  },
  {
    path: '/reset_password',
    authKey: 'password_reset',
    exact: true,
    name: 'Password reset form',
    component: PasswordResetForm
  },
  {
    path: '/dashboard',
    authKey: 'dashboard',
    transKey: 'breadcrumb_dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/clients',
    authKey: 'clients',
    transKey: 'breadcrumb_clients',
    exact: true,
    name: 'Clients',
    component: SubMenu
  },
  {
    path: '/clients/overview',
    authKey: 'clients',
    transKey: 'breadcrumb_clients_overview',
    exact: true,
    name: 'Overview',
    component: Clients
  },
  {
    path: '/clients/reseller-invoices',
    authKey: 'reseller_invoices',
    transKey: 'breadcrumb_invoices',
    exact: true,
    name: 'Reseller Invoices',
    component: ResellerInvoices
  },
  {
    path: '/clients/reseller-invoices/:id',
    authKey: 'reseller_invoices',
    transKey: 'breadcrumb_invoices_details',
    exact: false,
    name: 'Reseller Invoice Details',
    component: ResellerInvoiceDetails
  },
  {
    path: '/group-overview',
    authKey: 'group_overview',
    transKey: 'breadcrumb_group_overview',
    exact: true,
    name: 'Group Overview',
    component: GroupOverview
  },
  {
    path: '/phonebooks',
    authKey: 'phonebooks',
    transKey: 'breadcrumb_phonebooks',
    exact: true,
    name: 'Users',
    component: Phonebooks
  },
  {
    path: '/reports',
    authKey: 'reports',
    transKey: 'breadcrumb_reports',
    exact: true,
    name: 'Reports',
    component: SubMenu
  },
  {
    path: '/reports/cdr-viewer',
    authKey: 'cdr_viewer',
    transKey: 'breadcrumb_cdr_viewer',
    exact: true,
    name: 'CDR Viewer',
    component: CDRViewer
  },
  {
    path: '/reports/invoices',
    authKey: 'invoices',
    transKey: 'breadcrumb_invoices',
    exact: true,
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/reports/invoices/detail_:id',
    authKey: 'invoices',
    transKey: 'breadcrumb_invoices_details',
    exact: false,
    name: 'Invoice Details',
    component: InvoiceDetails
  },
  {
    path: '/reports/invoices/cdr_:id',
    authKey: 'invoices',
    transKey: 'breadcrumb_invoices_cdr',
    exact: false,
    name: 'Invoice CDR',
    component: InvoiceCDR
  },
  {
    path: '/configuration',
    authKey: 'configuration',
    transKey: 'breadcrumb_configuration',
    exact: true,
    name: 'Configuration',
    component: SubMenu
  },
  {
    path: '/extension',
    authKey: 'configuration_extension',
    transKey: 'breadcrumb_configuration_extension',
    exact: true,
    name: 'Extension',
    component: Extensions
  },
  {
    path: '/configuration/group',
    authKey: 'configuration_group',
    transKey: 'breadcrumb_configuration_group',
    exact: true,
    name: 'Group',
    component: Groups
  },
  {
    path: '/configuration/ring-group',
    authKey: 'configuration_ring_group',
    transKey: 'breadcrumb_configuration_ring_group',
    exact: true,
    name: 'Ring Group',
    component: RingGroups
  },
  {
    path: '/configuration/time-schedule',
    authKey: 'time_schedule',
    transKey: 'menu_time_schedule',
    exact: true,
    name: 'Time Schedule',
    component: TimeSchedule
  },
  {
    path: '/configuration/voicemail',
    authKey: 'voicemail',
    transKey: 'breadcrumb_voicemail',
    exact: true,
    name: 'Voicemail',
    component: Voicemail
  },
  {
    path: '/configuration/voicemail/:view-:id',
    authKey: 'voicemail',
    transKey: 'breadcrumb_voicemail_messages',
    exact: false,
    name: 'VoicemailMessages',
    component: VoicemailMessages
  },
  {
    path: '/configuration/wakeup-call',
    authKey: 'configuration_wakeup_call',
    transKey: 'breadcrumb_configuration_wakeup_call',
    exact: true,
    name: 'Wakeup Call',
    component: WakeupCall
  },
  {
    path: '/configuration/sites',
    authKey: 'configuration_sites',
    transKey: 'breadcrumb_configuration_sites',
    exact: true,
    name: 'Sites',
    component: Sites
  },
  {
    path: '/configuration/did-routing',
    authKey: 'configuration_did_routing',
    transKey: 'breadcrumb_configuration_did_routing',
    exact: true,
    name: 'DID Routing',
    component: DidRouting
  },
  {
    path: '/configuration/devices',
    authKey: 'configuration_devices',
    transKey: 'breadcrumb_configuration_devices',
    exact: true,
    name: 'Devices',
    component: Devices
  },
  {
    path: '/configuration/devices/:id',
    authKey: 'configuration_devices',
    transKey: 'breadcrumb_configuration_dss',
    exact: false,
    name: 'Dss',
    component: Dss
  },
  {
    path: '/configuration/fifo-queue',
    authKey: 'configuration_fifo_queue',
    transKey: 'breadcrumb_configuration_fifoqueue',
    exact: true,
    name: 'Fifo Queue',
    component: FifoQueue
  },
  {
    path: '/call-flow',
    authKey: 'call_flow',
    transKey: 'breadcrumb_configuration_callflow',
    exact: true,
    name: 'Call Flow',
    component: CallFlow
  },
  {
    path: '/call-flow-gui/:id',
    authKey: 'call_flow',
    transKey: 'breadcrumb_configuration_callflow',
    exact: false,
    name: 'Call Flow',
    component: CallFlowGUI
  },
  {
    path: '/configuration/media-library',
    authKey: 'configuration_media_library',
    transKey: 'breadcrumb_configuration_media_library',
    exact: true,
    name: 'Media Library',
    component: MediaLibrary
  },
  {
    path: '/impersonate/:group/:token',
    authKey: 'impersonate',
    transKey: 'breadcrumb_dashboard',
    exact: true,
    name: 'Impersonate User',
    component: Impersonate
  },
  {
    path: '/call-forwarding/',
    authKey: 'call_forwarding',
    transKey: 'breadcrumb_call_forwarding',
    exact: true,
    name: 'Call Forwarding',
    component: CallForwarding
  },
  {
    path: '/call-flow-toggles/',
    authKey: 'call_flow_toggles',
    transKey: 'dashboard_callflow_toggles',
    exact: true,
    name: 'CallFlow Toggles',
    component: CallFlowToggles
  },
  {
    path: '/phone',
    authKey: 'phone',
    transKey: 'breadcrumb_phone',
    exact: true,
    name: 'Phone',
    component: Phone
  },
  {
    path: '/help_support',
    authKey: 'help_support',
    transKey: 'breadcrumb_help_support',
    exact: true,
    name: 'Help',
    component: SubMenu
  },
  {
    path: '/help_support/cheat_sheet',
    authKey: 'help_support_cheat_sheet',
    transKey: 'breadcrumb_help_support',
    exact: true,
    name: 'Help',
    component: HelpSupport
  },
  {
    path: '/help_support/pricing',
    authKey: 'help_support_pricing',
    transKey: 'breadcrumb_help_support_pricing',
    exact: true,
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/integrations/:kind/activate/',
    authKey: 'integrations',
    transKey: 'breadcrumb_integrations',
    exact: false,
    name: 'Integrations',
    component: Integrations
  },
  {
    path: '/statistics',
    authKey: 'statistics',
    transKey: 'breadcrumb_statistics',
    exact: true,
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/live-statistic',
    authKey: 'live_statistic',
    transKey: 'breadcrumb_live_statistic',
    exact: true,
    name: 'Live Statistics',
    component: LiveStatistics
  }
];

export default routes;
